var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amounts-price-summary",class:[
    _vm.classes,
    {
      'amounts-price-summary--rebranding': _vm.isRebrandingChannel,
    },
  ],attrs:{"data-preload":[_vm.$options.name]}},[(_vm.isBusinessCustomer)?[_c('div',{staticClass:"amounts-summary-container"},[_c('table',[_c('tbody',[_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.subtotal-b-plus')))]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedSubtotal,
                }))+"\n            ")])]),_vm._v(" "),_vm._l((_vm.formattedDiscounts),function(discountCode){return [(
                (discountCode.code || discountCode.label) &&
                discountCode.value
              )?_c('tr',{key:discountCode.code,staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n                "+_vm._s(_vm.$t('amounts-price-summary.labels.discount') +
                  ': ' +
                  (discountCode.code || discountCode.label))+"\n                "),_c('Button',{staticClass:"remove-discount-btn",attrs:{"icon":"delete","icon-width":15,"icon-height":17,"on-click":() => _vm.toggleRemoveDiscountDialog(discountCode)}})],1),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s('-' +
                  _vm.$t('amounts-price-summary.amount-with-currency', {
                    '%amount%': discountCode.value,
                  }))+"\n              ")])]):_vm._e()]}),_vm._v(" "),_vm._l((_vm.formattedTaxes),function(tax){return _c('tr',{key:tax.type,staticClass:"amount-row"},[_c('th',[_vm._v("\n              "+_vm._s(tax.label)+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': tax.value,
                }))+"\n            ")])])}),_vm._v(" "),_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.shipping-cost')))]),_vm._v(" "),_c('td',{attrs:{"data-testid":"ShippingCost"}},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedShipping,
                }))+"\n            ")])])],2)])])]:[_c('div',{staticClass:"amounts-summary-container"},[_c('table',[_c('tbody',[_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.subtotal')))]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedSubtotal,
                }))+"\n            ")])]),_vm._v(" "),_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.shipping-cost')))]),_vm._v(" "),_c('td',{attrs:{"data-testid":"ShippingCost"}},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedShipping,
                }))+"\n            ")])])])])])],_vm._v(" "),_c('ProgressBar',{staticClass:"cart-amounts__progress-bar",class:[{ 'cart-amounts__progress-bar-checkout': _vm.isCheckout }],attrs:{"amount-lacking-for-free-shipping":_vm.amounts.amountLackingForFreeShipping,"standard-free-shipping-total":_vm.amounts.standardFreeShippingTotal}}),_vm._v(" "),(_vm.isBusinessCustomer)?[_c('div',{staticClass:"amounts-summary-container"},[_c('table',[_c('tbody',[(_vm.amounts.paymentExpenses)?_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.additional-cost')))]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedAdditional,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.bulkyGoods)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.bulky-goods-fee'))+"\n              "),_c('button',{staticClass:"bulky-goods-fee__dialog-button",on:{"click":function($event){return _vm.toggleBulkyGoodsDialog()}}},[_c('Icon',{attrs:{"name":"Help"}})],1)]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedBulkyGoods,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.riskyGoods)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.risky-goods-fee'))+"\n              "),_c('button',{staticClass:"risky-goods-fee__dialog-button",on:{"click":_vm.toggleRiskyGoodsDialog}},[_c('Icon',{attrs:{"name":"Help"}})],1)]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedRiskyGoods,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.exchangeDeposit)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.exchange_deposit'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedExchangeDeposit,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.deposit)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.deposit'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedBatteryDeposit,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.dropshippingItems)?_c('tr',{staticClass:"dropshipment"},[_c('td',{staticClass:"dropshipment-container"},[_c('Icon',{staticClass:"accordion_icon icon",attrs:{"name":"explanation-blue","width":"20"}}),_vm._v(" "),_c('span',{staticClass:"message"},[_vm._v("\n                "+_vm._s(_vm.$t('amounts-price-summary.dropshipment_message')))])],1)]):_vm._e(),_vm._v(" "),_c('tr',{staticClass:"amount-row-total"},[_c('th',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.total'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedSum,
                }))+"\n            ")])])])])]),_vm._v(" "),_c('div',[_c('Dialog',{ref:"removeDiscountDialogRef",staticClass:"risky-goods-fee__dialog",attrs:{"click-outside":true,"title":_vm.$t('amounts-price-summary.discount-dialog.title'),"is-rebranding-channel":_vm.isRebrandingChannel}},[_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('amounts-price-summary.discount-dialog.content', {
              '%code%': _vm.codeToBeRemoved.code,
            }))+"\n          "),_c('div',{staticClass:"delete-discount-dialog-btn"},[_c('Button',{attrs:{"title":_vm.$t('amounts-price-summary.discount-dialog.cancel'),"modifier":"secondary","on-click":_vm.closeRemoveDiscountDialog}}),_vm._v(" "),_c('Button',{staticClass:"confirm-delete-btn",attrs:{"title":_vm.$t('amounts-price-summary.discount-dialog.accept'),"modifier":"primary","on-click":_vm.confirmDiscountRemoval}})],1)])])],1)]:[_c('div',{staticClass:"amounts-summary-container"},[_c('table',[_c('tbody',[(_vm.amounts.paymentExpenses)?_c('tr',{staticClass:"amount-row"},[_c('th',[_vm._v(_vm._s(_vm.$t('amounts-price-summary.labels.additional-cost')))]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedAdditional,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.bulkyGoods)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.bulky-goods-fee'))+"\n              "),_c('button',{staticClass:"bulky-goods-fee__dialog-button",on:{"click":function($event){return _vm.toggleBulkyGoodsDialog()}}},[_c('Icon',{attrs:{"name":"Help"}})],1)]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedBulkyGoods,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.riskyGoods)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.risky-goods-fee'))+"\n              "),_c('button',{staticClass:"risky-goods-fee__dialog-button",on:{"click":_vm.toggleRiskyGoodsDialog}},[_c('Icon',{attrs:{"name":"Help"}})],1)]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedRiskyGoods,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.exchangeDeposit)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.exchange_deposit'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedExchangeDeposit,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.amounts.deposit)?_c('tr',{staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.deposit'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedBatteryDeposit,
                }))+"\n            ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.formattedDiscounts),function(discountCode){return [(
                (discountCode.code || discountCode.label) &&
                discountCode.value
              )?_c('tr',{key:discountCode.code,staticClass:"amount-row"},[_c('th',{staticClass:"th-multiple-items"},[_vm._v("\n                "+_vm._s(_vm.$t('amounts-price-summary.labels.discount') +
                  ': ' +
                  (discountCode.code || discountCode.label))+"\n                "),_c('Button',{staticClass:"remove-discount-btn",attrs:{"icon":"delete","icon-width":15,"icon-height":17,"on-click":() => _vm.toggleRemoveDiscountDialog(discountCode)}})],1),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s('-' +
                  _vm.$t('amounts-price-summary.amount-with-currency', {
                    '%amount%': discountCode.value,
                  }))+"\n              ")])]):_vm._e()]}),_vm._v(" "),(_vm.dropshippingItems)?_c('tr',{staticClass:"dropshipment"},[_c('td',{staticClass:"dropshipment-container"},[_c('Icon',{staticClass:"accordion_icon icon",attrs:{"name":"explanation-blue","width":"20"}}),_vm._v(" "),_c('span',{staticClass:"message"},[_vm._v("\n                "+_vm._s(_vm.$t('amounts-price-summary.dropshipment_message')))])],1)]):_vm._e(),_vm._v(" "),_c('tr',{staticClass:"amount-row-total"},[_c('th',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.labels.total'))+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedSum,
                }))+"\n            ")])]),_vm._v(" "),_c('tr',{staticClass:"amount-row-small"},[_c('th',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.net') + ' ')+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': _vm.formattedNet,
                }))+"\n            ")])]),_vm._v(" "),_vm._l((_vm.formattedTaxes),function(tax){return _c('tr',{key:tax.type,staticClass:"amount-row-small",attrs:{"data-testid":"tax-label"}},[_c('th',[_vm._v("\n              "+_vm._s(tax.label)+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.$t('amounts-price-summary.amount', {
                  '%amount%': tax.value,
                }))+"\n            ")])])})],2)]),_vm._v(" "),_c('Dialog',{ref:"removeDiscountDialogRef",staticClass:"risky-goods-fee__dialog",attrs:{"click-outside":true,"title":_vm.$t('amounts-price-summary.discount-dialog.title'),"is-rebranding-channel":_vm.isRebrandingChannel}},[_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('amounts-price-summary.discount-dialog.content', {
              '%code%': _vm.codeToBeRemoved.code,
            }))+"\n          "),_c('div',{staticClass:"delete-discount-dialog-btn"},[_c('Button',{attrs:{"title":_vm.$t('amounts-price-summary.discount-dialog.cancel'),"modifier":"secondary","on-click":_vm.closeRemoveDiscountDialog}}),_vm._v(" "),_c('Button',{staticClass:"confirm-delete-btn",attrs:{"title":_vm.$t('amounts-price-summary.discount-dialog.accept'),"modifier":"primary","on-click":_vm.confirmDiscountRemoval}})],1)])])],1)],_vm._v(" "),_c('Dialog',{ref:"riskyGoodsDialogRef",staticClass:"risky-goods-fee__dialog",attrs:{"click-outside":true,"title":_vm.$t('amounts-price-summary.labels.risky-goods-fee'),"is-rebranding-channel":_vm.isRebrandingChannel}},[_c('div',{staticClass:"risky-goods-fee__dialog-description"},[_vm._v("\n      "+_vm._s(_vm.$t('amounts-price-summary.descriptions.risky-goods-fee'))+"\n    ")])]),_vm._v(" "),_c('Dialog',{ref:"bulkyGoodsDialogRef",staticClass:"bulky-goods-fee__dialog",attrs:{"title":_vm.$t('amounts-price-summary.labels.bulky-goods-fee'),"is-rebranding-channel":_vm.isRebrandingChannel}},[_c('div',{staticClass:"bulky-goods-fee__dialog-description"},[_vm._v("\n      "+_vm._s(_vm.$t('amounts-price-summary.descriptions.bulky-goods-fee'))+"\n    ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }